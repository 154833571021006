export default {
  chain: {
    btc: 'BTC',
    ethereum: 'ETH / EVMs',
    dogecoin: 'Dogecoin',
    eos: 'EOS',
    iost: 'IOST',
    tron: 'TRON',
    cosmos: 'COSMOS',
    binance: 'Binance',
    nervos: 'Nervos',
    jingtum: 'Jingtum',
    solana: 'Solana',
    aptos: 'Aptos',
    polkadot: 'Polkadot',
    bch: 'BCH',
    ltc: 'LTC',
    conflux: 'Conflux',
    nostr: 'Nostr',
    sui: 'SUI',
  },
  main: {
    title: 'Key Generator',
    language: 'English',
    intro:
      'This tool is used to randomly generate public and private keys for various networks. The generation process can be done offline without an internet connection. The generator code has been open-sourced, and you can go and check it out and verify it.',
    tips:
      'We recommend that you generate your public and private keys offline. After generating the keys, please be sure to record and store your private key safely. Do not disclose private key information to anyone!',
    githubUrl: 'Open Source',
    publicKey: 'Address',
    privateKey: 'Private Key',
    gen: 'Generate',
    copy: 'Copy',
    copied: 'Copied!',
    failed: 'Copy Failed!',
    normalAddr: 'Normal Address',
    segwitAddr: 'SegWit Address',
    address: 'Address',
    changeChain: 'Choose Network',
  },
  follow: {
    title: 'Follow Us',
    desc1: "TokenPocket Staffs won't send you private messages!",
    desc2:
      "Caution! You're entering TokenPocket community, there might be someone impersonating us to send you private messages! Please be aware that, anyone who sends private messages might be a scammer! We will never contact you first!",
    desc3: 'Understood, enter',
  },
  COMMON: {
    LAYOUT: {
      features: 'Features',
      products: 'Products',
      buyCryptoFooter: 'Buy Crypto',
      mobileWallet: 'Mobile Wallet',
      mobileWalletDesc: 'Crypto & DeFi mobile wallet on blockchain.',
      hardwareWallet: 'Hardware Wallet',
      hardwareWalletDesc: 'Get your KeyPal, to guard your assets.',
      extensionWallet: 'Extension Wallet',
      extensionWalletDesc: 'A better wallet on your computer.',
      desktop: 'Desktop Wallet',
      fiveDegrees: '5Degrees',
      versionVerification: 'Version Verification',
      versionVerificationDesc: 'Verify your wallet.',
      approvalDetector: 'Approval Detector',
      approvalDetectorDesc: 'View and unapprove your approved tokens.',
      tokenSecurity: 'Token Security',
      tokenSecurityDesc: 'Verify if the token is secure by its contract address.',
      keyGenerator: 'Key Generator',
      keyGeneratorDesc:
        'Randomly generate public and private keys for various networks.',
      tokenProfile: 'Token Profile',
      tokenProfileDesc: 'Learn all information about the token.',
      tokenClaim: 'Token Claim',
      tokenClaimDesc: 'Claim your airdrop.',
      easyInscription: 'Easy Inscription',
      easyInscriptionDesc: 'A secure inscription tool.',
      nostrAssetsBatchSender: 'NostrAssets BatchSender',
      nostrAssetsBatchSenderDesc: 'A batch sending tool for NostrAssets.',
      BATCH_SENDER: 'BatchSender',
      BATCH_SENDER_DESC: 'Send tokens to multiple recipients at once.',
  
      trade: 'Trade',
      swap: 'Transit Swap',
      swapDesc: 'Trade with the best prices.',
      bridge: 'Bridge',
      bridgeDesc: 'Seamlessly cross assets from one chain to another.',
      buyCrypto: 'Buy',
      buyCryptoDesc: 'Buy cryptocurrency with your credit cards.',
      market: 'Market',
      marketDesc: 'Real-time insights to discover hot tokens.',
  
      information: 'Information',
      blockchainGuide: 'Blockchain Guide',
      tronWallet: 'TRON Guide',
      iostWallet: 'IOST Guide',
      tpMan: 'TP Man',
  
      developers: 'Developers',
      github: 'Github (TP-Lab)',
      devCenter: 'Dev Center',
      developersDesc: 'TokenPocket developer documentation.',
      subToken: 'Submit Token',
      subTokenDesc: 'Submit your token.',
      subDApp: 'Submit DApp',
      subDAppDesc: 'Submit your DApp, and show it after audit.',
      subNFT: 'Submit NFT',
      subNFTDesc: 'Submit your NFT & arts.',
      subChain: 'Submit Chain',
      subChainDesc: 'Submit your blockchain.',
      bugBounty: 'Bug Bounty',
  
      tools: 'Tools',
  
      build: 'Build',
  
      learn: 'Learn',
  
      explorer: 'Explorer',
  
      company: 'Company',
      about: 'About',
      careers: 'Careers',
      pressKit: 'Brand Kit',
      swagShop: 'Swag Shop',
  
      support: 'Support',
      helpCenter: 'Help Center',
      helpCenterDesc: 'Your go-to resources for answers and assistance.',
      contactUs: 'Contact Us',
      contactUsDesc: 'Share your suggestions and ask any questions.',
      forum: 'Forum',
      forumDesc: 'Enjoy your talks with the community members.',
  
      legal: 'Legal',
      privacyPolicy: 'Privacy Policy',
      terms: 'Terms of Use',

      toHome: 'Home',
      defiWallet: 'DeFi Wallet',
      ETHWallet: 'Ethereum Wallet',
      ethWallet: 'ETH Wallet',

      dappStore: 'DApp Store'
    }
  }
};
