
export default {
  chain: {
    btc: 'BTC',
    ethereum: 'ETH / EVMs',
    dogecoin: 'Dogecoin',
    eos: 'EOS',
    iost: 'IOST',
    tron: 'TRON',
    cosmos: 'COSMOS',
    binance: 'Binance',
    nervos: 'Nervos',
    jingtum: 'Jingtum',
    solana: 'Solana',
    aptos: 'Aptos',
    polkadot: 'Polkadot',
    bch: 'BCH',
    ltc: 'LTC',
    conflux: 'Conflux',
    nostr: 'Nostr',
    sui: 'SUI',
  },
  main: {
    title: 'キーージェネレーター',
    language: '日本語',
    intro:
      'このツールは、各ネットワークの公開鍵と秘密鍵をランダムに生成するために使用されます. 生成プロセスはネットワークから切断してオフラインにすることができます. ジェネレーターのコードはオープンソース化されています. 確認して検証することができます.',
    tips:
      '公開鍵と秘密鍵を生成するには、ネットワークから切断することをお勧めします。生成後は、必ず秘密鍵情報を記録して保管してください。秘密鍵情報は、誰にも開示したり開示したりしないでください。！',
    githubUrl: 'オープンソースアドレス',
    publicKey: 'アドレス',
    privateKey: '秘密鍵(プライベートキー)',
    gen: '生成する',
    copy: 'コピー',
    copied: 'コピーされました！',
    failed: 'コピーに失敗しました！',
    normalAddr: 'ノーマルアドレス',
    segwitAddr: 'セグウィットアドレス',
    address: 'アドレス',
    changeChain: '最下層を切り替える',
  },
  follow: {
    title: 'フォローする',
    desc1: 'TokenPocket スタッフからプライベート メッセージは送信されません!',
    desc2:
      '注意！ TokenPocket コミュニティに参加しようとしています。誰かが私たちになりすましてプライベート メッセージを送信している可能性があります。 プライベート メッセージを送信する人は詐欺師である可能性があることに注意してください。 最初に連絡することはありません！',
    desc3: '了解、入力'
  },
  COMMON: {
    LAYOUT: {
      features: 'プロダクト',
      products: 'プロダクト',
      buyCryptoFooter: '暗号通貨を購入する',
      mobileWallet: 'モバイルウォレット',
      mobileWalletDesc: 'ブロックチェーンのモバイルウォレット。',
      hardwareWallet: 'ハードウェアウォレット',
      hardwareWalletDesc: '資産を守るためにKeyPalを手に入れよう！',
      extensionWallet: 'ブラウザ拡張ウォレット',
      extensionWalletDesc: 'コンピューター上でのより良いウォレット',
      desktop: 'デスクトップウォレット',
      fiveDegrees: '5Degrees',
      versionVerification: 'バージョン検証',
      versionVerificationDesc: 'ウォレットの検証',
      approvalDetector: 'アプルーバルディテクター',
      approvalDetectorDesc: 'アプルーブの表示とリボーク',
      tokenSecurity: 'トークンセキュリティ',
      tokenSecurityDesc: 'トークンのコントラクトアドレスを監査',
      keyGenerator: 'キージェネレーター',
      keyGeneratorDesc: 'さまざまなネットワークの公開鍵と秘密鍵をランダムに生成',
      tokenProfile: 'トークンプロファイル',
      tokenProfileDesc: 'トークンに関する全ての情報を確認',
      tokenClaim: 'トークンクレーム',
      tokenClaimDesc: 'エアドロップを受け取る',
      easyInscription: 'イージーインスクリプション',
      easyInscriptionDesc: '安全かつ簡単なインスクリプションのためのツール',
      nostrAssetsBatchSender: 'NostrAssetsバッチトランスファー',
      nostrAssetsBatchSenderDesc: 'NostrAssetsのためのバッチトランスファーツール',
      BATCH_SENDER: 'バッチトランスファー',
      BATCH_SENDER_DESC: '複数のアドレスに一度にトークンを転送',

      trade: '取引',
      swap: 'TransitSwap',
      swapDesc: 'ベストレートで取引。',
      bridge: 'Bridge',
      bridgeDesc: 'Ensuring seamless asset liquidity.',
      buyCrypto: '購入',
      buyCryptoDesc: 'クレジットカードで暗号資産を購入',
      market: '市場',
      marketDesc: 'リアルタイムインサイトでトレンドのトークンを見つける',

      information: '詳細',
      blockchainGuide: 'ブロックチェーンガイド',
      tronWallet: 'TRON Guide',
      iostWallet: 'IOST Guide',
      tpMan: 'TPマン',

      developers: '開発者',
      github: 'Github (TP-Lab)',
      devCenter: '開発者センター',
      developersDesc: 'TokenPocket開発者向けドキュメント',
      subToken: 'トークンを投稿',
      subTokenDesc: 'トークンを投稿',
      subDApp: 'DAppの投稿',
      subDAppDesc: 'DAppを投稿し、監査後に表示',
      subNFT: 'NFTの投稿',
      subNFTDesc: 'NFTとアート作品の投稿',
      subChain: 'チェーンを投稿',
      subChainDesc: 'ブロックチェーンをを投稿',
      bugBounty: 'バグバウンティ',

      tools: 'ツール',

      build: 'ビルド',

      learn: '学ぶ',

      explorer: 'エクスプローラー',

      company: '会社',
      about: 'TokenPocketについて',
      careers: 'キャリア',
      pressKit: 'ブランドキット',
      swagShop: 'スワッグショップ',

      support: 'サポート',
      helpCenter: 'ヘルプセンター',
      helpCenterDesc: '問題解決のためのアシスタント',
      contactUs: 'お問い合わせ',
      contactUsDesc: '提案を共有と質問',
      forum: 'フォーラム',
      forumDesc: 'コミュニティメンバーとの会話を楽しむ',

      legal: '法的情報',
      privacyPolicy: 'プライバシーポリシー',
      terms: '利用規約',

      toHome: 'ホーム',
      defiWallet: 'DeFiウォレット',
      ETHWallet: 'イーサリアムウォレット',
      ethWallet: 'ETHウォレット',

      dappStore: 'DAppストア'
    }
  }
};
